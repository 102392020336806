import { useKeycloak } from '@react-keycloak/web'
import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'
import { lazily } from 'react-lazily'
import { Head } from './components/layout/Head'
import { hasAccess } from './helpers/functions'
import { Forbidden } from './pages/Forbidden'
const { NotFoundPage } = lazily(() => import('./pages/NotFound'))
const { MyFilesPage } = lazily(() => import('./pages/MyFiles'))

export const App: FC = () => {
  const { keycloak } = useKeycloak()

  if (!keycloak.authenticated) {
    keycloak.login()
    return null
  }

  return (
    <Routes>
      {hasAccess(keycloak) ? (
        <>
          <Route path="/" element={<Head />}>
            <Route path="/files" element={<MyFilesPage />} />
            <Route path="/" element={<MyFilesPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Route>
        </>
      ) : (
        <>
          <Route path="*" element={<Forbidden />} />
        </>
      )}
    </Routes>
  )
}
