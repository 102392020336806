import { FC } from 'react'
import { ViewProfile } from './styles'

interface ProfileProps {
  avatar?: string
  isImage?: boolean
}

export const Profile: FC<ProfileProps> = ({ avatar, isImage }) => (
  <ViewProfile>
    {isImage ? (
      <img src={`data:image/png;base64,${avatar}`} />
    ) : (
      avatar?.slice(0, 2)
    )}
  </ViewProfile>
)
