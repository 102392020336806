import {
  transitions,
  positions,
  AlertComponentPropsWithStyle,
} from 'react-alert'
import { X, Info, XCircle, CheckCircle, AlertTriangle } from 'react-feather'
import { useTranslation } from 'react-i18next'

import View, { AlertType } from './styles'
import { P1, P3 } from '../../styles'
import { FC } from 'react'

export const defaultOptions = {
  timeout: 4000,
  position: positions.TOP_RIGHT,
  transition: transitions.FADE,
  containerStyle: {
    zIndex: 999999,
  },
}

export const Alert: FC<AlertComponentPropsWithStyle> = ({
  close,
  message,
  style,
  options,
}) => {
  const { t } = useTranslation()

  const head = {
    info: {
      icon: <Info />,
      title: t('components.alert.info'),
    },
    error: {
      icon: <XCircle />,
      title: t('components.alert.error'),
    },
    success: {
      icon: <CheckCircle />,
      title: t('components.alert.success'),
    },
    warning: {
      icon: <AlertTriangle />,
      title: t('components.alert.warning'),
    },
  }

  return (
    <View type={options.type as AlertType} style={style}>
      <div>
        {head[options.type as AlertType].icon}

        <P1>{head[options.type as AlertType].title}</P1>
      </div>

      <P3>{message}</P3>

      <X size="1.6rem" onClick={close} />
    </View>
  )
}
