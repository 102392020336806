import { FormHandles } from '@unform/core'
import axios from 'axios'
import { differenceInMinutes, format, parseISO } from 'date-fns'
import { KeycloakInstance } from 'keycloak-js'
import { RefObject } from 'react'
import { TableRow } from '../components/table'
import { format as formatCNPJ } from 'cnpj'
import { Status } from '../components/Status'
import { ChevronRight } from 'react-feather'
import { Colors } from '../styles'

/**
 * Truncate text with '...' at the end
 */
export const truncate = (text?: string, length: number = 0) =>
  text && text.length && text.length > length
    ? `${text.substring(0, length)}...`
    : text

export const isMasterCase = (user: KeycloakInstance) =>
  !!user?.tokenParsed?.realm_access?.roles?.includes('is_master_case')

export const hasAccess = (user: KeycloakInstance) => {
  return !!user?.tokenParsed?.case_management_group
}

const camelToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)

export const toFormData = (data: any) =>
  Object.keys(data).reduce((acc, curr) => {
    if (!data[curr] && data[curr] !== false) {
      return acc
    }

    let value = data[curr]

    if (value === true) {
      value = 1
    } else if (value === false) {
      value = 0
    }

    acc.append(camelToSnakeCase(curr), value)
    return acc
  }, new FormData())

export const prettyPastDate = (date: string) => {
  const diff = differenceInMinutes(new Date(), parseISO(date))
  return diff < 1
    ? { key: 'pretty-past-date.just_now', diff }
    : diff < 2
    ? { key: 'pretty-past-date.a_minute_ago', diff }
    : diff < 60
    ? { key: 'pretty-past-date.minutes_ago', diff }
    : diff < 120
    ? { key: 'pretty-past-date.a_hour_ago', diff }
    : diff < 1440
    ? { key: 'pretty-past-date.hours_ago', diff: Math.floor(diff / 60) }
    : diff < 2880
    ? { key: 'pretty-past-date.a_day_ago', diff }
    : { key: 'pretty-past-date.days_ago', diff: Math.floor(diff / 1440) }
}

interface ParseAxiosErrorInput {
  err: any
  fallbackMessage?: string
  formRef?: RefObject<FormHandles>
}

export const parseAxiosError: (input: ParseAxiosErrorInput) => string = ({
  err,
  fallbackMessage,
  formRef,
}) => {
  fallbackMessage = fallbackMessage || 'Unexpected error'

  if (!axios.isAxiosError(err)) {
    return err.message
  }

  if (Object.keys(err.response?.data.errors || {}).length === 0) {
    return err.message || fallbackMessage
  }

  const firstErrorKey = Object.keys(err.response?.data.errors)[0]

  if (formRef) {
    formRef.current?.setFieldError(firstErrorKey, 'toggle_error')
  }

  const errArray = err.response?.data.errors[firstErrorKey]

  if (errArray.length === 0) {
    return err.message || fallbackMessage
  }

  return errArray[0]
}

export const opportunityToTableRow: (
  opportunity: any,
  t: any,
  navigate: any,
  user: KeycloakInstance,
  stages?: any[],
) => TableRow = (opportunity, t, navigate, user, stages = []) => ({
  columns: [
    {
      type: 'text',
      label: '#',
      value: `#${opportunity.deal_id}`,
    },
    {
      type: 'text',
      label: t('pages.new-opportunity.prospect_name'),
      value: opportunity.prospect_name.label,
    },
    {
      type: 'text',
      label: t('pages.new-opportunity.cnpj'),
      value: formatCNPJ(opportunity.cnpj.label),
    },
    {
      type: 'text',
      label: t('pages.new-opportunity.created_at'),
      value: format(parseISO(opportunity.created_at), 'dd/MM/yyyy'),
    },
    {
      type: 'text',
      label: t('pages.new-opportunity.requester'),
      value: opportunity?.user.name,
    },
    {
      type: 'text',
      label: t('pages.new-opportunity.progress'),
      value: (() => {
        const stage = stages.find((stage) => stage.id === opportunity.stage_id)
        return stage ? stage.name : '-'
      })(),
    },
    {
      type: 'element',
      label: 'Status',
      element: <Status status={opportunity.status} fullWidth />,
    },
    {
      type: 'element',
      label: t('pages.new-opportunity.view'),
      element: (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <ChevronRight
            style={{ cursor: 'pointer' }}
            color={Colors.gray._500}
            onClick={() => navigate(`/opportunities/${opportunity.id}`)}
          />
        </div>
      ),
    },
  ],
})

export const toPaginateInfo = ({
  current_page: currentPage,
  from,
  last_page: lastPage,
  per_page: perPage,
  to,
  total,
}: {
  [K: string]: number
}) => ({
  currentPage,
  from,
  lastPage,
  perPage,
  to,
  total,
})

export const isObjectsEquals = (a: any, b: any) => {
  const aKeys = Object.keys(a ?? {})
  const bKeys = Object.keys(b ?? {})

  for (let i = 0; i < aKeys.length; i++) {
    const key = aKeys[i]
    if (a[key] !== b[key]) {
      return false
    }
  }

  return aKeys.length === bKeys.length
}
