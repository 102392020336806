import { useKeycloak } from '@react-keycloak/web'
import { FC } from 'react'
import { XOctagon } from 'react-feather'
import { Colors, H1, P1 } from '../../styles'

import { View } from './styles'

export const Forbidden: FC = () => {
  const { keycloak } = useKeycloak()
  return (
    <View>
      <XOctagon size="100px" color={Colors.status.error} />
      <H1>Você não tem acesso a este serviço</H1>
      <P1>
        Clique{' '}
        <button
          onClick={() => {
            keycloak?.logout()
          }}
        >
          aqui
        </button>{' '}
        para acessar com outra conta
      </P1>
    </View>
  )
}
