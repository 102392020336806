import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import { Breakpoints, Colors, Typography, P2, easing } from '../../../styles'

const { gray, support, secondary } = Colors
const { basic, rubber } = easing
const { weight, size } = Typography

interface ViewProps {
  isAuthenticated?: boolean
  isMenuOpen?: boolean
}

interface ItemProps {
  active?: boolean
}

interface DropdownProps {
  type?: 'profile'
}

export const Logo = styled(NavLink)`
  width: fit-content;
  display: flex;
  padding: 1rem 5.2rem;
  background-color: ${support._100};

  img {
    width: 15rem;
  }
`

export const Navigate = styled.ul`
  display: flex;
  height: 100%;
  margin-left: 5.6rem;
  align-items: baseline;
  white-space: nowrap;

  > span {
    display: none;
  }
`

export const Dropdown = styled.div<DropdownProps>`
  top: 3.2rem;
  display: flex;
  padding: 0.8rem 0;
  opacity: 0;
  position: absolute;
  min-width: 13.2rem;
  transition: all 0.8s ${rubber};
  text-align: left;
  border-radius: 0.4rem;
  flex-direction: column;
  pointer-events: none;
  background-color: ${gray._000};
  z-index: 1;

  ${P2} {
    color: ${gray._500};
    padding: 1.6rem;
    transition: all 0.3s ${basic};

    &:hover {
      color: ${secondary};
    }

    &:not(:first-child) {
      border-top: 0.1rem solid ${gray._300};
    }

    &.active {
      color: ${secondary};
    }
  }

  ${({ type }) =>
    type === 'profile' &&
    css`
      ${P2} {
        display: flex;
        align-items: center;

        &:nth-of-type(2) {
          flex-direction: column;
          display: none;

          &:hover {
            color: ${gray._500};
          }

          span.lang {
            transition: all 0.3s ${basic};
            width: 100%;
            text-align: center;

            &.active {
              color: ${secondary};
              font-weight: ${weight.bold};
            }

            &:hover {
              color: ${secondary};
            }

            &:not(:last-child) {
              margin-bottom: 1.2rem;
            }
          }
        }

        &.active {
          display: flex;
        }

        & > svg {
          stroke: ${gray._500};
          width: 1.6rem;
          margin-left: 1.2rem;
        }

        span.icon {
          width: 2.4rem;
          height: 2.4rem;
          display: flex;
          align-items: center;
          background-color: ${rgba(support._000, 0.1)};
          border-radius: 50%;
          justify-content: center;
          margin-right: 1.2rem;

          svg {
            width: 1.6rem;
            height: 1.6rem;
            stroke: ${support._100} !important;
          }
        }
      }
    `}
`

export const Item = styled(P2)<ItemProps>`
  color: ${support._200};
  cursor: pointer;
  height: 100%;
  padding: 2.4rem 2rem;
  display: flex;
  transition: all 0.3s ${basic};
  align-items: center;
  text-align: left;

  svg {
    margin-left: 1rem;
  }

  & > li {
    display: flex;
    transition: all 0.3s ${basic};
    align-items: center;

    svg {
      top: 0.2rem;
      position: relative;
      transform: rotate(0);
      transition: all 0.3s ${basic};
    }
  }

  &:hover {
    color: ${gray._000};
  }

  &:not(:first-child) {
    margin-left: 0.4rem;
  }

  .active {
    color: ${gray._000};
    font-weight: ${weight.semiBold};
  }

  ${({ active }) =>
    active &&
    css`
      color: ${gray._000};
      font-weight: ${weight.semiBold};
    `}

  :focus-within {
    & > li {
      color: ${gray._000};
      font-weight: ${weight.semiBold};
    }

    ${Dropdown} {
      top: 7.6rem;
      opacity: 1;
      pointer-events: all;
    }
  }
`

export const UserProfile = styled.div`
  height: 100%;
  display: flex;
  padding: 1.4rem 3.2rem 1.4rem 2.4rem;
  border-left: 0.2rem solid ${support._100};
  margin-left: auto;
  align-items: center;

  & > .whoami {
    & > ${P2} {
      color: ${gray._000};
      display: flex;
      align-items: center;
    }
  }

  > button {
    width: 3.8rem;
    height: 3.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ${basic};
    margin-left: 2.4rem;
    border-radius: 50%;
    background-color: ${support._100};

    &.toggle-menu {
      display: none;
    }

    svg {
      stroke: ${gray._000};
    }

    &:hover {
      background-color: ${support._000};
    }

    :focus-within {
      ${Dropdown} {
        top: 7.6rem;
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  ${Dropdown} {
    right: 1.6rem;
  }
`

export const View = styled.nav<ViewProps>`
  max-width: 100vw;
  top: 0;
  width: 100%;
  min-height: 6.8rem;
  z-index: 98;
  display: flex;
  position: ${({ isAuthenticated }) =>
    isAuthenticated ? 'fixed' : 'relative'};
  align-items: center;
  background-color: ${secondary};

  ${({ isMenuOpen }) =>
    isMenuOpen &&
    css`
      position: fixed;
    `}

  @media screen and (max-width: ${Breakpoints.lg}) {
    ${Navigate} {
      margin-left: 2.4rem;

      & > ${Item} {
        padding: 2.4rem 1.2rem;
      }
    }
  }

  @media screen and (max-width: ${Breakpoints.xl}) {
    ${Navigate} {
      margin-left: 2.4rem;

      & > ${Item} {
        padding: 2.4rem 1.2rem;
      }
    }
  }

  @media screen and (max-width: ${Breakpoints.md}) {
    background-color: ${support._100} !important;

    ${Navigate} {
      background-color: ${secondary};
      height: calc(100vh - 6.8rem);
      position: absolute;
      right: 0;
      top: 6.8rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 0;

      > * {
        opacity: 0;
        transition: opacity 0.3s ${basic};
      }

      & > ${Item} {
        padding: 3rem 1rem;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
        margin: 0;
        width: 100%;

        &,
        li {
          font-size: ${size.m1};
        }

        ${Dropdown} {
          display: none;
          position: relative;
          top: 0;
          padding-top: 1rem;
          min-width: 100%;
          text-align: left;
          border-radius: 0;
          flex-direction: column;
          background-color: transparent;

          ${P2} {
            color: ${support._200} !important;
            padding: 1.8rem 4rem;
            border: 0;
            font-size: ${size.s3};
            border-left: 0.5rem solid ${rgba(gray._400, 0.5)};

            &:hover {
              color: ${gray._000};
            }

            &.active {
              color: ${secondary};
            }
          }
        }
      }

      ${({ isMenuOpen }) =>
        isMenuOpen &&
        css`
          width: 85vw;
          padding: 2rem 1rem;

          > * {
            opacity: 1;
          }

          > span {
            display: block;
            height: 100vh;
            width: 15vw;
            background-color: ${rgba(gray._600, 0.6)};
            position: absolute;
            left: -15vw;
            top: -6.8rem;
          }

          ${Item} {
            :focus-within {
              & > li {
                color: ${gray._000};
                font-weight: ${weight.semiBold};
              }

              ${Dropdown} {
                display: flex;
                top: 0;
                opacity: 1;
                pointer-events: all;
              }
            }
          }
        `}
    }

    ${UserProfile} {
      border: none;
      background-color: ${support._100};
      justify-content: flex-start;
      padding: 0 2rem !important;

      > ${P2} {
        font-size: ${size.m1};
        display: none;
      }

      > button {
        display: none;

        &.toggle-menu {
          display: flex;
          transform: scale(1.5) translateX(-1rem);
          transition: all 0s;
        }
      }

      ${({ isMenuOpen }) =>
        isMenuOpen &&
        css`
          background-color: ${secondary};
          padding: 1.4rem 3.2rem 1.4rem 1.2rem;
          position: absolute;
          top: 0;
          right: 0;
          width: 85vw;
          align-items: center;

          > ${P2} {
            display: flex;
            white-space: nowrap;
          }

          > button {
            display: flex;
            transform: scale(1.2) !important;
          }
        `}
    }
  }
`

export const Badge = styled.span`
  position: absolute;
  background-color: ${Colors.status.error};
  color: ${Colors.gray._000};
  font-size: ${Typography.size.s1};
  padding: 2px 6px;
  top: -10px;
  right: -10px;
  border-radius: 10px;
`
