import { FC } from 'react'
import { View, Wrapper } from './styles'

import loaderAnimationData from '../../assets/animations/loader.json'

import Lottie from 'react-lottie'

export const LoadingPage: FC = () => (
  <View>
    <Wrapper>
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: loaderAnimationData,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid',
          },
        }}
        height="20rem"
      />
    </Wrapper>
  </View>
)
