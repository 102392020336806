import styled from 'styled-components'
import { Colors } from '../../styles'

export const View = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  button {
    padding: 0;
    margin: 0;
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: bold;
    color: ${Colors.primary._000};
  }
`
