import styled from 'styled-components'
import { Colors } from '.'

export const Main = styled.div`
  display: flex;
  transition: all 0.3s ease-in-out;
  flex-direction: column;
`

interface LayoutProps {
  stickyToHeader?: boolean
}

export const Layout = styled.div<LayoutProps>`
  display: flex;
  margin-top: ${({ stickyToHeader }) => (stickyToHeader ? '7rem' : '11.2rem')};
  flex-direction: column;
`

export const Card = styled.div`
  background-color: ${Colors.gray._000};
  padding: 2.5rem 2rem;
  border-radius: 0.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`
