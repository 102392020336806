import { KeycloakInstance } from 'keycloak-js'

type MenuItem = LinkMenuItem | DropdownMenuItem

interface BaseMenuItem {
  shouldRender: (keycloak: KeycloakInstance) => boolean
  labelKey: string | ((keycloak: KeycloakInstance) => string)
}

interface LinkMenuItem extends BaseMenuItem {
  type: 'link'
  href: string
}

interface DropdownMenuItem extends BaseMenuItem {
  type: 'dropdown'
  items: LinkMenuItem[]
}

export const menuData: MenuItem[] = [
  {
    type: 'link',
    href: '/',
    labelKey: (keycloak) => 'head.navigate.my-files.user',
    shouldRender: () => true,
  },
]
