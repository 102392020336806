import React from 'react'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import { render } from 'react-dom'
import { Provider as AlertProvider } from 'react-alert'
import { App } from './app'
import { keycloak } from './services/keycloak'

import './config/i18n'
import { LoadingPage } from './pages/Loading'
import { BrowserRouter } from 'react-router-dom'
import { GlobalStyles } from './styles'
import { Alert, defaultOptions } from './components/Alert'

render(
  <React.StrictMode>
    <React.Suspense fallback={<LoadingPage />}>
      <AlertProvider template={Alert} {...defaultOptions}>
        <ReactKeycloakProvider
          authClient={keycloak}
          LoadingComponent={<LoadingPage />}
        >
          <BrowserRouter>
            <App />
            <GlobalStyles />
          </BrowserRouter>
        </ReactKeycloakProvider>
      </AlertProvider>
    </React.Suspense>
  </React.StrictMode>,
  document.querySelector('#root'),
)
