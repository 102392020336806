import { FC, MouseEvent, useState } from 'react'
import {
  LogOut,
  Menu,
  X,
  ChevronDown /* Globe, ChevronUp */,
} from 'react-feather'
// import classNames from 'classnames'
import { Main, P2 } from '../../../styles'
import { View, Logo, UserProfile, Dropdown, Navigate, Item } from './styles'
import LogoWhite from '../../../assets/logo.svg'
import { Outlet } from 'react-router'
import { truncate } from '../../../helpers/functions'
import { useTranslation } from 'react-i18next'
import { Profile } from '../../profile'
import { useKeycloak } from '@react-keycloak/web'
import { NavLink, useLocation } from 'react-router-dom'
import { menuData } from './menu-data'
import { KeycloakInstance } from 'keycloak-js'

export const Head: FC = () => {
  // const [notifications, setNotifications] =
  const [isMenuOpen, setMenuOpen] = useState(false)
  // const [showLanguage, onShowLanguage] = useState(false)
  const { t /*, i18n */ } = useTranslation()
  const { keycloak } = useKeycloak()
  const location = useLocation()

  const getLabelKey = (
    labelKey: string | ((keycloak: KeycloakInstance) => string),
  ) => (typeof labelKey === 'string' ? labelKey : labelKey(keycloak))

  return (
    <Main>
      <View isAuthenticated={keycloak.authenticated} isMenuOpen={isMenuOpen}>
        <Logo to="/">
          <img src={LogoWhite} alt="" />
        </Logo>
        {keycloak.authenticated && (
          <>
            <Navigate>
              {menuData.map((menuInfo, index) => {
                if (!menuInfo.shouldRender(keycloak)) {
                  return null
                }

                switch (menuInfo.type) {
                  case 'link':
                    return (
                      <Item
                        key={index}
                        as="li"
                        onClick={() => setMenuOpen(false)}
                      >
                        <NavLink to={menuInfo.href} end>
                          {t(getLabelKey(menuInfo.labelKey))}
                        </NavLink>
                      </Item>
                    )
                  case 'dropdown':
                    return (
                      <Item key={index} as="button">
                        <li role="presentation">
                          {t(getLabelKey(menuInfo.labelKey))}
                          <ChevronDown size="1.2rem" />
                        </li>
                        <Dropdown onClick={() => setMenuOpen(false)}>
                          {menuInfo.items.map((itemsInfo, itemsIndex) => {
                            if (!itemsInfo.shouldRender(keycloak)) {
                              return null
                            }

                            return (
                              <P2
                                key={itemsIndex}
                                as={NavLink}
                                to={itemsInfo.href}
                                activeClassName={
                                  location.pathname === itemsInfo.href
                                    ? 'active'
                                    : ''
                                }
                                onClick={(e: MouseEvent) =>
                                  (e.target as HTMLElement).blur()
                                }
                              >
                                {t(getLabelKey(itemsInfo.labelKey))}
                              </P2>
                            )
                          })}
                        </Dropdown>
                      </Item>
                    )
                  default:
                    return null
                }
              })}
            </Navigate>
            <UserProfile>
              <div className="whoami">
                <P2 isBold title={keycloak?.tokenParsed?.name}>
                  {truncate(keycloak?.tokenParsed?.name, 16)}
                </P2>
              </div>
              <button type="button">
                <Profile avatar={keycloak?.tokenParsed?.name} />
                <Dropdown type="profile">
                  {/* <P2 onClick={() => onShowLanguage(!showLanguage)}>
                    <span className="icon">
                      <Globe />
                    </span>
                    {t('head.actions.language')}
                    {showLanguage ? <ChevronUp /> : <ChevronDown />}
                  </P2>
                  <P2 className={showLanguage ? 'active' : undefined}>
                    {languages.map((lang) => (
                      <span
                        className={classNames('lang', {
                          active: lang.key === i18n.language,
                        })}
                        key={lang.key}
                        role="presentation"
                        onClick={() => {
                          i18n.changeLanguage(lang.key)
                        }}
                      >
                        {lang.label}
                      </span>
                    ))}
                  </P2> */}
                  <P2
                    onClick={() => {
                      keycloak?.logout()
                    }}
                  >
                    <span className="icon">
                      <LogOut />
                    </span>
                    {t('head.actions.logout')}
                  </P2>
                </Dropdown>
              </button>
              <button
                type="button"
                className="toggle-menu"
                onClick={() => setMenuOpen((old) => !old)}
              >
                {isMenuOpen ? <X size="2rem" /> : <Menu size="2rem" />}
              </button>
            </UserProfile>
          </>
        )}
      </View>
      <Outlet />
    </Main>
  )
}
