import { rgba } from 'polished'
import styled, { css } from 'styled-components'
import { Colors } from '../../styles'

interface ViewProps {
  status: 'open' | 'won' | 'lost' | 'deleted'
  fullWidth: boolean
}

const statusColors = {
  won: Colors.status.success,
  lost: Colors.status.warning,
  open: Colors.support._300,
  deleted: Colors.status.error,
} as const

export const View = styled.span<ViewProps>`
  display: inline-block;
  padding: 0.6rem 1.2rem;
  border-radius: 5px;

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}

  text-align: center;
  text-transform: uppercase;

  ${({ status }) => {
    const color = statusColors[status] ?? Colors.gray._400
    return css`
      background-color: ${rgba(color, 0.2)};
      color: ${color} !important;
    `
  }}
`
